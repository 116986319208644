import React from 'react'
import styled from 'styled-components'
import { colors } from 'src/styles/variables'
import { media } from 'src/styles/util'

const Section = styled.section`
  width: 100%;
  background-color: ${colors.white};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  ${media.mobile`
    padding: 20px;
  `}
  ${media.tablet`
    height: fit-content;
    padding: 20px 40px 20px 20px;
  `}
`

const Box = styled.div`
  background-color: ${colors.sustain};
  width: 100%;
  border-radius: 20px;
  max-width: 1100px;
  margin: 0 auto;
  text-align: center;

  ${media.mobile`
    padding: 20px 40px;
  `}

  ${media.desktop`
    padding: 50px 80px;
  `}

  button {
    margin: 30px 0;
    display: inline-block;
  }

`

export const GreenBox = ({children}: any) => {
  return (
    <Section>
      <Box>
        {children}
      </Box>
    </Section>
  )
}
