import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Headline, LargeP, P, MonoP } from '../shared/text'
import { colors } from 'src/styles/variables'
import { media } from 'src/styles/util'
import rePurpose from 'src/assets/img/sustainability/rePurpose.svg'

const Section = styled.section`
  width: 100%;
  background-color: ${colors.white};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  ${media.mobile`
    margin-top: 0;
    padding: 20px 0 0 0;
  `}
  ${media.tablet`
    margin-top: 0;
    height: fit-content;
  `}

  ${media.desktop`
    margin-top: 140px;
  `}

  .text-container {
    position: absolute;
    z-index: 1;
    text-align: center;
    h1,p {
      color: #fff;
    }

    p {

      ${media.mobile`
        font-size: 20px;
      `}

      ${media.desktop`
        font-size: 26px;
      `}

      margin: 30px 0;
    }

    img {

      ${media.mobile`
        max-width: 150px;
      `}

      ${media.desktop`
        max-width: 300px;
      `}

    }

  }
`

const ImageWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  ${media.mobile`
    margin-top: -20px;
    height: 500px;
  `}
  ${media.tablet`
    margin-top: -24px;
    height: 700px;
  `}
  ${media.desktop`
    margin-top: -36px;
    height: 950px;
  `}
`

const query = graphql`
  query {
    aboveTheFold: file(relativePath: { regex: "/sustainability/hero.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 1400)
      }
    }
  }
`

const AboveTheFold = () => {
  const images = useStaticQuery(query)

  return (
    <Section>
      <div className="text-container">
        <Headline>Certified<br/>Plastic Negative</Headline>
        <P>In partnership with...</P>
        <img src={rePurpose} />
      </div>
      <ImageWrapper className="about-hero-image">
        <GatsbyImage
          image={getImage(images.aboveTheFold)}
          alt="Woman and her dog looking at each other"
          style={{ height: '100%', width: '100%' }}
          imgStyle={{ objectFit: 'cover' }}
        />
      </ImageWrapper>
    </Section>
  )
}

export default AboveTheFold
