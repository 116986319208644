import React from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import Guide from './Guide'
import AboveTheFold from './AboveTheFold'
import Protect from './Protect'
import { GreenBox } from './GreenBox'
import { HowItWorks } from './HowItWorks'
import { OtherWays } from './OtherWays'
import { Headline, LargeP, ExternalLink } from '../shared/text'
import { SecondaryButton } from 'src/components/shared/buttons'
import { colors } from 'src/styles/variables'
import { media } from 'src/styles/util'

const Par = styled(LargeP)`
  color: #fff;
`
const IntroText = styled.div`
  background-color: #fff;

  h1 {
    color: #1F1646;
    max-width: 1100px;
    margin: 0 auto;
    text-align: center;

    ${media.mobile`
      font-size: 34px;
      padding: 50px 30px;
    `}

    ${media.desktop`
      font-size: 64px;
      padding: 50px 30px;
    `}

  }
`

const StyledHeadline = styled(Headline)`
  color: #fff;
  margin-bottom: 20px;
`

const SustainabilityPage = () => {

  const handleClick = (destination: string) => {
    navigate(destination)
  }

  return (
    <>
      <AboveTheFold/>
      <IntroText>
        <Headline>
        Finn recovers twice as much plastic from the environment as we use in our packaging.
        </Headline>
      </IntroText>
      <GreenBox>
        <Par>
          It’s estimated that about <strong>300 million pounds of plastic waste</strong> is produced annually by the pet food industry, in just the United States alone.<br/><br/>According to the industry, much of the plastic packaging is made of non-recyclable or hard-to-recycle materials, which equates to about <strong>99% of pet food packaging ending in landfills or oceans.</strong>
        </Par>
      </GreenBox>
      <HowItWorks/>
      <GreenBox>
        <StyledHeadline>rePurpose Global</StyledHeadline>
        <Par>
          rePurpose Global is the world's leading Plastic Action Platform dedicated to fighting plastic waste and eliminates millions of pounds of plastic waste from nature every year. In so doing, rePurpose Global positively impacts the lives of 10,000+ marginalized waste workers and community members worldwide.
        </Par>
        <ExternalLink to="https://www.business.repurpose.global/impact">
          <SecondaryButton
            copy="Learn more"
            arrow
            color={colors.sustain}
            bg="white"
          />
        </ExternalLink>
      </GreenBox>
      <Guide/>
      <Protect/>
      <OtherWays/>
    </>
  )
}

export default SustainabilityPage
