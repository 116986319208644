import React from 'react'
import styled from 'styled-components'
import { Headline, LargeP, P, MonoP, H4 } from '../shared/text'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { colors } from 'src/styles/variables'
import { media } from 'src/styles/util'
import DropHeader from '../shared/DropHeader'

const Section = styled.section`
  width: 100%;
  background-color: ${colors.white};
  height: fit-content;
  position: relative;
  ${media.mobile`
    padding: 0 0 20px 0;
  `}
  ${media.tablet`
    padding: 50px 0 80px 0;
  `}
  ${media.desktop`
    padding: 80px 0 80px 0;
  `}
`

const StyledLargeP = styled(LargeP)`
  text-align: center;
  ${media.mobile`
    max-width: 300px;
    margin: 40px auto 0 auto;
  `}
  ${media.tablet`
    max-width: 525px;
    margin: 40px auto 0 auto;
  `}
  ${media.desktop`
    margin: 70px auto 0 auto;
  `}
`

const Grid = styled.div`
  max-width: 1100px;
  margin: 50px auto 0;
  display: grid;
  grid-gap: 30px;

  .step {
    text-align: center;
    display: grid;
    grid-gap: 20px;
    align-items: flex-start;
  }

  ${media.mobile`
    grid-template-columns: 1fr;
    padding: 0 30px;
  `}
  ${media.tablet`
    grid-template-columns: 1fr;
  `}
  ${media.desktop`
    grid-template-columns: 1fr 1fr 1fr;
  `}
`

const query = graphql`
  query {
    candle: file(relativePath: { regex: "/sustainability/candle.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 600)
      }
    }
    plant: file(relativePath: { regex: "/sustainability/plant-pot.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 600)
      }
    }
    storage: file(relativePath: { regex: "/sustainability/storage.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 600)
      }
    }
  }
`



export const OtherWays = () => {
  const images = useStaticQuery(query)
  const title = "Other ways\nto use your tins"

  const items = [
    {
      copy: 'Candle\nHolder',
      img: getImage(images.candle),
      alt: 'Candle Holder'
    },
    {
      copy: 'Plant\nPot',
      img: getImage(images.plant),
      alt: 'Plant Pot'
    },
    {
      copy: 'Storage &\nOrganization',
      img: getImage(images.storage),
      alt: 'Storage & Organization'
    }
  ]

  return (
    <Section>
      <DropHeader title={title} />
      <StyledLargeP>
      Don't let our tins go to waste! Get creative with them. The possibilities are endless, so let your imagination run wild!
      </StyledLargeP>
      <Grid>
        {items.map((item, idx) => (
          <div className="step" key={`step-${idx}`}>
            <GatsbyImage
              image={item.img}
              alt={item.alt}
              style={{ height: 400 }}
              imgStyle={{ objectFit: 'contain' }}
            />
            <H4>{item.copy}</H4>
          </div>
        ))}
      </Grid>
    </Section>
  )
}
