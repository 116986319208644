import React from 'react'
import { PageProps } from 'gatsby'

import Layout from 'src/layouts'
import SEO from 'src/components/shared/SEO'
import SustainabilityPage from 'src/components/Sustainability'

const Sustainability: React.FC<PageProps> = ({ location }) => {
  return (
    <>
      <SEO title="Plastic Negative" location={location} />
      <Layout location={location}>
        <SustainabilityPage />
      </Layout>
    </>
  )
}

export default Sustainability
