import React from 'react'
import styled from 'styled-components'
import { Headline, LargeP, P, MonoP, H4 } from '../shared/text'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { colors } from 'src/styles/variables'
import { media } from 'src/styles/util'
import DropHeader from '../shared/DropHeader'

const Section = styled.section`
  width: 100%;
  background-color: ${colors.white};
  height: fit-content;
  position: relative;
  ${media.mobile`
    padding: 0 0 20px 0;
  `}
  ${media.tablet`
    padding: 0 0 80px 0;
  `}
  ${media.desktop`
    padding: 0 0 80px 0;
  `}
`

const StyledLargeP = styled(LargeP)`
  text-align: center;
  ${media.mobile`
    max-width: 300px;
    margin: 40px auto 0 auto;
  `}
  ${media.tablet`
    max-width: 525px;
    margin: 40px auto 0 auto;
  `}
  ${media.desktop`
    margin: 70px auto 0 auto;
  `}
`

const Grid = styled.div`
  max-width: 1100px;
  margin: 50px auto 0;
  display: grid;
  grid-gap: 30px;

  .step {
    text-align: center;
    display: grid;
    grid-gap: 20px;
  }

  ${media.mobile`
    grid-template-columns: 1fr;
    padding: 0 20px;
  `}
  ${media.tablet`
    grid-template-columns: 1fr;
  `}
  ${media.desktop`
    grid-template-columns: 1fr 1fr 1fr;
  `}
`

const query = graphql`
  query {
    measure: file(relativePath: { regex: "/sustainability/measure.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 600)
      }
    }
    fund: file(relativePath: { regex: "/sustainability/fund.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 600)
      }
    }
    reduce: file(relativePath: { regex: "/sustainability/reduce.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 600)
      }
    }
  }
`



export const HowItWorks = () => {
  const images = useStaticQuery(query)
  const title = "How it works"

  const items = [
    {
      copy: 'Measure',
      img: getImage(images.measure),
      alt: 'Measure',
      description:
        'Finn donates a percentage of every product sold to fund the collection of low-value plastic from the environment.'
    },
    {
      copy: 'Fund',
      img: getImage(images.fund),
      alt: 'Fund',
      description:
        'Low-value plastic is collected, cleaned and ethically processed by a women-owned recovery co-op in Aurangabad, India.'
    },
    {
      copy: 'Reduce',
      img: getImage(images.reduce),
      alt: 'Reduce',
      description:
        'Finn works with rePurpose Global to reduce the use of virgin plastics, supporting a more sustainable business model'
    }
  ]

  return (
    <Section>
      <DropHeader title={title} />
      {/* <StyledLargeP>
      Reduce your pup's carbon paw print: Tips and tricks for recycling our packaging!
      </StyledLargeP> */}
      <Grid>
        {items.map((item, idx) => (
          <div className="step" key={`step-${idx}`}>
            <GatsbyImage
              image={item.img}
              alt={item.alt}
              style={{ height: 180 }}
              imgStyle={{ objectFit: 'contain' }}
            />
            <H4>{item.copy}</H4>
            <LargeP>{item.description}</LargeP>
          </div>
        ))}
      </Grid>
    </Section>
  )
}
